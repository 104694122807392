import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
// import { useQuery } from "@apollo/react-hooks";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

// import { EMPLOYEE_BY_ID } from "../../queries/fetchPeople";
import { fetchPeopleImage } from "../../store/actions/uiActions";
// import { isEmptyOrNull } from "../../utils";

const useStyles = makeStyles((theme) => {
  return {
    avatarCir: {
      marginRight: "9px",
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.primary,
    },
  };
});

const PeopleImage = ({ name }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const email = useSelector(
  //   (state) => state.auth.user && state.auth.user.email,
  //   shallowEqual
  // );

  const peopleImage = useSelector(
    (state) => state.ui.peopleImage,
    shallowEqual
  );

  // eslint-disable-next-line no-unused-vars
  // const { loading, error, data } = useQuery(EMPLOYEE_BY_ID, {
  //   variables: { id: email || "", field: "EMAIL_ADDRESS" },
  //   skip: isEmptyOrNull(email),
  // });

  useEffect(() => {
    dispatch(fetchPeopleImage());
  }, []);

  return (
    (peopleImage || name) && (
      <Avatar alt={name} src={peopleImage} className={classes.avatarCir} />
    )
  );
};

PeopleImage.propTypes = {
  name: PropTypes.string,
};

export default PeopleImage;
