import React, { Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import OverlaySpinner from "./OverlaySpinner";

const GlobalSpinner = () => {
  const showSpinner = useSelector(
    (state) => state.global.showSpinner,
    shallowEqual
  );
  return <Fragment>{showSpinner && <OverlaySpinner />}</Fragment>;
};

export default GlobalSpinner;
