import React, { useState, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IconButton, ClickAwayListener } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { AAGlobalAppSearch } from "../../analytics/AnalyticsCapture";

import CONFIG from "../../config/appConfig";

const useStyles = makeStyles((theme) => {
  return {
    searchInputCont: {
      minHeight: "40px",
      backgroundColor: theme.palette.background.secondary,
      width: "200px",
      [theme.breakpoints.up("lg")]: {
        width: "360px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "400px",
      },
    },
    searchInput: {
      paddingRight: theme.spacing(0.5),
    },
    adornment: {
      padding: 0,
    },
    searchBtn: {
      color: theme.palette.common.white,
      padding: "8px 16px",
      [theme.breakpoints.up("lg")]: {
        padding: "8px 32px",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    notchedOutline: {
      borderColor: "transparent !important",
    },
    clearIcnBtn: {
      padding: 0,
      color: theme.palette.action.active,
      transform: "scale(1, 1)",
      transition: theme.transitions.create(["transform", "color"], {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    iconButtonHidden: {
      transform: "scale(0, 0)",
    },
  };
});

const MAX_LENGTH = 30;

const Search = ({ handleSearch }) => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState("");

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    setValue((value) => value.trim());
  };

  const handleCancel = () => {
    setValue("");
  };

  const handleSearchRequest = () => {
    if (value !== "") {
      handleSearch(false);
      AAGlobalAppSearch(value);
      history.push(`${CONFIG.APP_URL.SEARCH_URI}?term=${value}`);
    }
  };

  const handleKeyUp = (e) => {
    if (e.charCode === 13 || e.key === "Enter") {
      handleSearchRequest();
    } else if (e.charCode === 27 || e.key === "Escape") {
      handleCancel();
    }
  };

  const handleClickAway = () => {
    handleSearch(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <TextField
        id="standard-full-width"
        placeholder="Search"
        color="primary"
        variant="outlined"
        autoComplete="off"
        autoFocus
        value={value}
        onChange={handleInput}
        inputProps={{ className: classes.searchInput, maxLength: MAX_LENGTH }}
        InputProps={{
          endAdornment: (
            <Fragment>
              <IconButton
                onClick={handleCancel}
                className={classNames(classes.clearIcnBtn, {
                  [classes.iconButtonHidden]: value === "",
                })}
              >
                <ClearIcon />
              </IconButton>
              <InputAdornment position="end">
                <Button
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  color="primary"
                  variant="contained"
                  className={classes.searchBtn}
                  onClick={handleSearchRequest}
                >
                  Search
                </Button>
              </InputAdornment>
            </Fragment>
          ),
          className: classes.searchInputCont,
          classes: {
            adornedEnd: classes.adornment,
            notchedOutline: classes.notchedOutline,
          },
          onBlur: handleBlur,
          onKeyUp: handleKeyUp,
        }}
        size="small"
      />
    </ClickAwayListener>
  );
};

export default Search;
