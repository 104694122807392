import InitialState from "../initialState";
import { createSlice } from "@reduxjs/toolkit";

const initialState = InitialState.globalData;

export const globalSlice = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    showSpinner: (state) => {
      state.showSpinner = true;
    },
    hideSpinner: (state) => {
      state.showSpinner = false;
    },
    showAlert: (state, { payload }) => {
      state.alert = payload;
    },
    hideAlert: (state, { payload }) => {
      state.alert = payload;
    },
    showFrontAlert: (state, { payload }) => {
      state.frontAlert = payload;
    },
    hideFrontAlert: (state, { payload }) => {
      state.frontAlert = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAlert,
  hideAlert,
  showSpinner,
  hideSpinner,
  showFrontAlert,
  hideFrontAlert,
} = globalSlice.actions;

export default globalSlice.reducer;
