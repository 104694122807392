const toolDetailsInitialState = {
  toolId: "",
  title: "",
  featuredImgUrl: "",
  toolInfo: "",
  productSummary: "",
  toolUrl: "",
  sip: false,
  visible: true,
  caseCodeValidity: "",
  topicName: "",
  subTopicName: "",
  useCases: [],
  kpi: "",
  keyFeatures: "",
  keyQuestions: "",
  moreInfo1: "",
  moreInfo2: "",
  moreInfo3: "",
  moreInfo1Title: "",
  moreInfo2Title: "",
  moreInfo3Title: "",
  moreInfoKeysArr: [],
  toolScreens: [],
  infoDownload: [],
  toolVideos: "",
  viewTool: "",
  hrid: [],
  contactInfo: "",
  isTop4: "",
  top4Order: "",
};

export default toolDetailsInitialState;
