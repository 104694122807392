import React from "react";
import { Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

import CONFIG from "../config/appConfig";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      backgroundColor: theme.palette.background.secondary,
      padding: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
    },
    subText: {
      textAlign: "center",
    },
  };
});

const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Alert severity="error">
          <Typography variant="subtitle2">Page Not Found!</Typography>
        </Alert>
        <Typography variant="subtitle2" className={classes.subText}>
          Navigate to &nbsp;
          <Link
            component={RouterLink}
            className={classes.info}
            to={CONFIG.APP_URL.HOME}
          >
            Home
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default NotFound;
