import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";

//ToDo: Move to constants
const links = [
  {
    name: "BCG.com",
    link: "https://www.bcg.com",
  },
  {
    name: "CFS Tutorial",
    link: "https://www.bcg.com",
  },
  {
    name: "IT Connect",
    link: "https://www.bcg.com",
  },
];

const useStyles = makeStyles((theme) => {
  return {
    footer: {
      padding: theme.spacing(2, 4),
      backgroundColor: theme.palette.common.white,
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
    footerText: {
      fontSize: theme.typography.pxToRem(12),
    },
    footerSubText: {
      fontSize: theme.typography.pxToRem(10),
    },
    footerLinkText: {
      display: "flex",
      fontSize: theme.typography.pxToRem(12),
      margin: theme.spacing(0, 0.5),
      [theme.breakpoints.up("lg")]: {
        margin: theme.spacing(0, 1),
      },
      [theme.breakpoints.up("xl")]: {
        margin: theme.spacing(0, 2),
      },
    },
    footerArrow: {
      fontSize: "1rem",
      marginLeft: theme.spacing(0.5),
    },
  };
});

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <Typography className={classes.footerText} color="textSecondary">
              <b>
                Copyright &#169; {new Date().getFullYear()} by Boston Consulting Group. All rights
                reserved.
              </b>
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={5} md={4} lg={3} xl={4}>
          <Box display="flex" justifyContent="flex-end">
            {links.map((link, i) => {
              return (
                <Link href={link.link} key={i} rel="noopener" target="_blank">
                  <Box
                    className={classes.footerLinkText}
                    color="secondary.main"
                    fontWeight="boldText.fontWeight"
                  >
                    {link.name}
                    <span>
                      <ArrowForwardIosOutlinedIcon
                        className={classes.footerArrow}
                      />
                    </span>
                  </Box>
                </Link>
              );
            })}
          </Box>
        </Grid> */}
      </Grid>
    </footer>
  );
};

export default Footer;
