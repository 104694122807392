import { createReducer } from "@reduxjs/toolkit";

import InitialState from "../initialState"; 
import {
  setTopicData,
  setPeopleImage,
  setUserHrId,
  setReorderTopicId,
  setUsersByHrId,
  setUsersByEmail
} from "../actions/uiActions";

const initialState = InitialState.ui;

const uiReducer = createReducer(initialState, {
  [setTopicData]: (state, { payload: data }) => {
    state.topics = data;
  },
  [setPeopleImage]: (state, { payload: data }) => {
    state.peopleImage = data;
  },
  [setUserHrId]: (state, { payload: data }) => {
    state.userHrId = data;
  },
  [setReorderTopicId]: (state, { payload }) => {
    state.reorderTopicId = payload;
  },
  [setUsersByHrId]: (state, { payload }) => {
    state.users = payload;
  },
  [setUsersByEmail]: (state, { payload }) => {
    state.userbyEmail = payload;
  },
});

export default uiReducer;
