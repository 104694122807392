import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink, withRouter } from "react-router-dom";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import { IconButton, Badge, Typography } from "@material-ui/core";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Drawer from "@material-ui/core/Drawer";
//import Avatar from "@material-ui/core/Avatar";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchTopic } from "../../store/actions/uiActions";
import SearchIcon from "@material-ui/icons/Search";

//import UserProfile from "../UserProfile";
import Notification from "../Notification";
import CONFIG from "../../config/appConfig";
import PeopleImage from "../PeopleImage";
import Search from "../Search";
//import { getQueryParams } from "../../utils";

const useStyles = makeStyles((theme) => {
  return {
    nav: {
      height: "68px",
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    navLink: {
      padding: theme.spacing(0, 2),
      position: "relative",
      height: "100%",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none",
      },
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 1.4),
      },
    },
    "navLink--active": {
      backgroundColor: theme.palette.teritary.main,
      "& $navLinkText": {
        fontWeight: theme.typography.boldText.fontWeight,
        fontSize: theme.typography.boldText.fontSize,
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    navLinkText: {
      color: theme.palette.common.white,
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    menu: {
      minWidth: "200px",
    },
    menuItem: {
      margin: theme.spacing(0, 0.5),
      borderRadius: "4px",
      "&:focus": {
        backgroundColor: "unset",
      },
      "&:hover": {
        backgroundColor: theme.palette.background.primary,
      },
    },
    menuItemDisable: {
      margin: theme.spacing(0, 0.5),
      borderRadius: "4px",
      backgroundColor: theme.palette.background.secondary,
      pointerEvents: "none",
      opacity: 0.5,
    },
    menuLink: {
      width: "100%",
    },
    expandIcon: {
      verticalAlign: "middle",
    },
    IconBtn: {
      color: "#ddd",
    },
    profileBtn: {
      color: "#fff",
      "&:hover": {
        background:
          "transparent linear-gradient(90deg, #3EAD91 0%, #1F5749 100%) 0% 0% no-repeat padding-box",
      },
      "&:active": {
        background:
          "transparent linear-gradient(90deg, #3EAD91 0%, #1F5749 100%) 0% 0% no-repeat padding-box",
      },
    },
    paperSidebar: {
      top: "68px",
      height: "auto",
      bottom: "0",
      background:
        "transparent linear-gradient(180deg, #184439 0%, #3EAD91 100%) 0% 0% no-repeat padding-box",
    },
    navRightLink: {
      padding: theme.spacing(0, 1),
      position: "relative",
      height: "100%",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
    "navRightLink--active": {
      backgroundColor: theme.palette.primary.main,
    },
    navText: {
      padding: 0,
      "&:hover": {
        textDecoration: "none",
      },
    },
    addNew: {
      color: "#fff",
      textDecoration: "none",
      background: "#29BA74",
      padding: "4px",
      width: "146px",
      height: "40px",
      borderRadius: "4px",
      border: "1px solid #3EAD91",
      opacity: "1",
      marginRight: "15px",
      "&:hover": {
        color: "#fff",
        textDecoration: "none",
        background: "#29BA74",
      },
      [theme.breakpoints.down("md")]: {
        width: "105px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "170px",
      },
    },
    avatarCir: {
      marginRight: "9px",
    },
    searchIconBtn: {
      color: theme.palette.common.white,
      background: "#7b977b",
      height: "40px",
      width: "40px",
      borderRadius: "100px",
      position: "relative",
      marginLeft: "20px",
      "&:hover": {
        transform: "scale(1.3)",
        backgroundColor: "#197A56",
      },
    },
  };
});

const NavBar = ({ location: UrlLocation }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [locationTopics, setLocationTopics] = useState(null);
  const [userState, setUserState] = useState(false);
  const [notification, setNotification] = useState(false);
  const [search, setSearch] = useState(false);

  const name = useSelector(
    (state) => state.auth.user && state.auth.user.name,
    shallowEqual
  );
  const { cfsAdmin } = useSelector((state) => state.auth.role, shallowEqual);

  const topics = useSelector((state) => state.ui.topics, shallowEqual);
  useEffect(() => {
    dispatch(fetchTopic());
    //const { term = "" } = getQueryParams(UrlLocation.search);
    //term && handleSearchIcnClick(true);
  }, []);

  let link;

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setUserState((userState) => !userState);
  };

  const toggleDrawerNotification = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setNotification((notification) => !notification);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    const tempBtn = event.currentTarget.getBoundingClientRect();
    const left = tempBtn.left - 16;
    const bottom = tempBtn.bottom + 22;
    setLocationTopics({ top: bottom, left: left });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchIcnClick = (value) => {
    setSearch(value);
  };

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <nav className={classes.nav}>
        <Link
          component={RouterLink}
          to="/"
          color="textPrimary"
          className={classnames(classes.navLink, {
            [classes["navLink--active"]]:
              UrlLocation.pathname === "/" && !Boolean(anchorEl),
          })}
        >
          <Typography variant="subtitle1" className={classes.navLinkText}>
            Home
          </Typography>
        </Link>
        <Link
          color="textPrimary"
          className={classnames(classes.navLink, {
            [classes["navLink--active"]]: Boolean(anchorEl),
          })}
        >
          <Typography
            onClick={handleClick}
            variant="subtitle1"
            className={classes.navLinkText}
          >
            Topics
            {Boolean(anchorEl) ? (
              <ExpandLessOutlinedIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreOutlinedIcon className={classes.expandIcon} />
            )}
          </Typography>
        </Link>
        <Menu
          id="simple-menu"
          //anchorEl={anchorEl} enable for setting auto position
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={1}
          getContentAnchorEl={null}
          anchorReference="anchorPosition" // remove for setting auto position
          anchorPosition={locationTopics} // remove for setting auto position
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disablePortal // remove for if element to be part of body
          classes={{ paper: classes.menu }}
        >
          {topics &&
            topics.length > 0 &&
            topics.map((item, i) => {
              // if (item.topicCode === "TN") {
              //   link = {
              //     href: "http://tas.bcg.com/",
              //     target: "_blank",
              //     rel: "noopener",
              //   };
              // } else
              // if (item.subTopics.length === 0 && item.topicCode !== "TN") {
              //   link = {
              //     to: "",
              //     component: "",
              //   };
              // } else {
              //   link = {
              //     to: `/tools/${item.topicID}`,
              //     component: RouterLink,
              //   };
              // }
              link = {
                  to: `/tools/${item.topicID}`,
                  component: RouterLink,
                };
              return (
                <MenuItem
                  className={classes.menuItem}
                  // className={
                  //   item.subTopics.length === 0 && item.topicCode !== "TN"
                  //     ? classes.menuItemDisable
                  //     : classes.menuItem
                  // }
                  key={i}
                  onClick={handleClose}
                >
                  <Link
                    underline="none"
                    className={classes.menuLink}
                    // component={RouterLink}
                    // to={`/tools/${item.topicID}`}
                    {...link}
                    variant="body1"
                    color="textPrimary"
                  >
                    {item.topicName}
                  </Link>
                </MenuItem>
              );
            })}
        </Menu>

        <Link
          component={RouterLink}
          to={CONFIG.APP_URL.CONTACTUS_URI}
          color="textPrimary"
          className={classnames(classes.navLink, {
            [classes["navLink--active"]]:
              UrlLocation.pathname === CONFIG.APP_URL.CONTACTUS_URI,
          })}
        >
          <Typography variant="subtitle1" className={classes.navLinkText}>
            Contact Us
          </Typography>
        </Link>
        <Box ml={1}>
          {!search ? (
            <IconButton
              onClick={() => handleSearchIcnClick(true)}
              className={classes.searchIconBtn}
            >
              <SearchIcon />
            </IconButton>
          ) : (
            <Search handleSearch={handleSearchIcnClick} />
          )}
        </Box>
      </nav>
      <nav className={classes.nav}>
        {cfsAdmin && (
          <Link
            component={RouterLink}
            to={CONFIG.APP_URL.ADDNEWTOOL_URI}
            color="textPrimary"
            className={classes.navText}
          >
            <Button variant="contained" className={classes.addNew}>
              + Add New
            </Button>
          </Link>
        )}

        {false && (
          <React.Fragment>
            <Box
              className={classnames(classes.navRightLink, {
                [classes["navRightLink--active"]]: notification,
              })}
              onClick={toggleDrawerNotification}
            >
              <IconButton disableRipple disableFocusRipple>
                <Badge badgeContent={5} color="error">
                  <NotificationsNoneIcon className={classes.IconBtn} />
                </Badge>
              </IconButton>
            </Box>
            <Drawer
              classes={{ paper: classes.paperSidebar }}
              anchor="right"
              open={notification}
              onClose={toggleDrawerNotification}
              BackdropProps={{ style: { backgroundColor: "transparent" } }}
            >
              <Notification anchor="right" />
            </Drawer>
          </React.Fragment>
        )}

        <React.Fragment>
          <Box
            className={classnames(classes.navRightLink, {
              [classes["navRightLink--active"]]: userState,
            })}
            // onClick={toggleDrawer}
          >
            <IconButton disableRipple disableFocusRipple>
              <PeopleImage name={name} />
              <Typography variant="subtitle1" className={classes.navLinkText}>
                {name}
                {/* {userState ? (
                  <ExpandLessOutlinedIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreOutlinedIcon className={classes.expandIcon} />
                )} */}
              </Typography>
            </IconButton>
          </Box>
          {/* <Drawer
            classes={{ paper: classes.paperSidebar }}
            anchor="right"
            open={userState}
            onClose={toggleDrawer}
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
          >
            <UserProfile anchor="right" />
          </Drawer> */}
        </React.Fragment>
      </nav>
    </Box>
  );
};

export default withRouter(NavBar);
