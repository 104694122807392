import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import List from "@material-ui/core/List";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

//Mock Record for notification
const data = [
  {
    desc: "Selena Smith uploaded new tool under Strategy",
    dateTime: "1 week ago",
  },
  {
    desc: "Selena Smith uploaded new tool under Strategy",
    dateTime: "2 week ago",
  },
  {
    desc: "Selena Smith uploaded new tool under Strategy",
    dateTime: "2 week ago",
  },
  {
    desc: "Selena Smith uploaded new tool under Strategy",
    dateTime: "1 day ago",
  },
  {
    desc: "Selena Smith uploaded new tool under Strategy",
    dateTime: "1 day ago",
  },
];

const useStyles = makeStyles((theme) => ({
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(3),
  },
  gutterSpace: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  paperSidebar: {
    top: "68px",
    height: "auto",
    bottom: "0",
    background:
      "transparent linear-gradient(180deg, #184439 0%, #3EAD91 100%) 0% 0% no-repeat padding-box",
  },

  newreqBtn: {
    background: "#30C1D7",
    fontSize: "12px",
    color: "#fff",
    width: "94px",
    height: "23px",
    textTransform: "capitalize",
    padding: 0,
    margin: 0,
    borderRadius: "4px",
    opacity: "1",
    "&:hover": {
      background: "#30C1D7",
      color: "#fff",
    },
  },
  description: {
    color: "#fff",
    fontSize: "14px",
    width: "70vw",
  },
  dateTime: {
    color: "#ccc",
    fontSize: "10px",
    top: "53%",
  },
  setHover: {
    "&:hover": {
      background: "#3EAD91",
    },
  },
  notifyHead: {
    color: "#fff",
    fontSize: "20px",
    marginLeft: "30px",
    marginTop: "32px",
    marginBottom: "15px",
  },
  showallBtn: {
    background: "none",
    border: "1px solid #fff",
    color: "#fff",
    left: "10%",
    marginTop: "10px",
    "&:hover": {
      background: "none",
    },
  },
  showTxt: {
    color: "#fff",
  },
  Button: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

function Lists(anchor) {
  const classes = useStyles();
  //Data Loading
  const [loading, setLoading] = useState(true);

  const [notificationData, setNotificationData] = useState([]);
  const [notificationTotal, setNotificationTotal] = useState();

  const fecthNotificationRequest = () => {
    setLoading(true);
    try {
      setLoading(false);
      setNotificationData(data);
      setNotificationTotal(("0" + data.length).slice(-2));
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fecthNotificationRequest();
  }, []);

  if (loading) {
    return (
      <main>
        <Loading />
      </main>
    );
  }
  return (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <Typography className={classes.notifyHead} variant="h6">
        Notification {notificationTotal}
      </Typography>
      <List>
        {notificationData.map((notificationData) => {
          const { desc, dateTime } = notificationData;
          return (
            <ListItem
              alignItems="flex-start"
              className={classes.setHover}
              classes={{ gutters: classes.gutterSpace }}
            >
              <ListItemText
                primary={
                  <>
                    <Button variant="contained" className={classes.newreqBtn}>
                      <Typography variant="caption">New Request</Typography>
                    </Button>
                  </>
                }
                secondary={
                  <>
                    <Typography
                      variant="caption"
                      className={classes.description}
                    >
                      {desc}
                    </Typography>
                  </>
                }
              />
              <ListItemSecondaryAction className={classes.dateTime}>
                {dateTime}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Button variant="contained" className={classes.showallBtn}>
        <Typography
          variant="caption"
          className={classes.showTxt}
          onClick={() => console.log("new req page")}
        >
          Show All Notification
        </Typography>
      </Button>
    </div>
  );
}

Lists.propTypes = {};

export default Lists;
