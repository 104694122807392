const data = {
  page: {
    pageInfo: {
      pageName: "",
      staffSystemID: "",
    },
    category: {
      primaryCategory: "",
    },
  },
  source: {
    application: {
      applicationName: "",
    },
  },
};
window.digitalData = data;
export default window.digitalData;
