/* eslint-disable no-console */
import "./config-AA.js";
import Hashes from "jshashes";

const data = {
  page: {
    pageInfo: {
      pageName: "",
      staffSystemID: "",
    },
    category: {
      primaryCategory: "",
    },
  },
  source: {
    application: {
      applicationName: "",
    },
  },
  internal: {
    search: {
      term: "",
    },
  },
  tab: {
    name: "",
  },
  tile: {
    name: "",
  },
  link: {
    toolSearch: "",
    toolContactSearch: "",
  },
  email: {
    toolContactSearch: "",
  },
};

function AAcommon(email) {
  if (email) {
    const staffSystemID = new Hashes.SHA256().hex(email.toLowerCase());
    data.page.pageInfo.staffSystemID = staffSystemID;
  }
  data.page.category.primaryCategory = "CFS Tools";
  data.source.application.applicationName = "CFS Tools";
  window.digitalData = Object.assign(window.digitalData, data);
  if (window._satellite) {
    window._satellite.track("Global_Page_Load");
    window._satellite.pageBottom();
  }
}

// function AAPageLoad(name, email) {
//   try {
//     const staffSystemID = new Hashes.SHA256().hex(email.toLowerCase());
//     data.page.pageInfo.pageName = name;
//     data.page.pageInfo.staffSystemID = staffSystemID;
//     data.page.category.primaryCategory = "CFS Tools";
//     window.digitalData = Object.assign(window.digitalData, data);
//     if (window._satellite) {
//       window._satellite.track("Global_Page_Load");
//       window._satellite.pageBottom();
//     }
//   } catch (error) {
//     console.log("Error: AAPageLoad > ", error);
//   }
// }

function AAPageLoadFromHome(name) {
  try {
    data.page.pageInfo.pageName = name;
    localStorage.setItem("pageName", name);
    window.digitalData = Object.assign(window.digitalData, data);
    if (window._satellite) {
      window._satellite.track("CFS Home");
      window._satellite.pageBottom();
    }
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function AAPageLoadFromContact(pageName) {
  try {
    data.page.pageInfo.pageName = pageName;
    localStorage.setItem("pageName", pageName);
    window.digitalData = Object.assign(window.digitalData, data);
    if (window._satellite) {
      window._satellite.track("CFS_Contact");
      window._satellite.pageBottom();
    }
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function AAPageLoadFromAddOrEditTool(pageName) {
  try {
    data.page.pageInfo.pageName = pageName;
    window.digitalData = Object.assign(window.digitalData, data);
    window._satellite.track("CFS Add/edit");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function getNameForTopcis(id) {
  if (id == 1) {
    return " CORPORATE FINANCE";
  } else if (id == 2) {
    return " STRATEGY";
  } else if (id == 5) {
    return " DISRUPTION & REINVENTION";
  } else return " TRANSACTION";
}

function AAPageLoadFromTopicsDeepDive(pageName, id) {
  try {
    data.page.pageInfo.pageName = pageName + " - " + getNameForTopcis(id);
    window.digitalData = Object.assign(window.digitalData, data);
    window._satellite.track("CFS Topic Deep Dive");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function AAPageLoadFromSearchPage(pageName) {
  try {
    data.page.pageInfo.pageName = pageName;
    window.digitalData = Object.assign(window.digitalData, data);
    window._satellite.track("CFS Search");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function AAGlobalAppSearch(searchValue) {
  try {
    data.internal.search.term = searchValue;
    window.digitalData = Object.assign(window.digitalData, data);
    window._satellite.track("Internal_Search");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function AAToolSearchClick(seachKey, toolClickedName) {
  try {
    data.internal.search.term = seachKey;
    data.link.toolSearch = toolClickedName;
    window.digitalData = Object.assign(window.digitalData, data);
    window._satellite.track("Tools_Search_Link_Clicks");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function AAToolContactSearchNameClick(seachKey, toolClickedName) {
  try {
    data.internal.search.term = seachKey;
    data.link.toolContactSearch = toolClickedName;
    window.digitalData = Object.assign(window.digitalData, data);
    window._satellite.track("Tools_Contact_Search_Link_Clicks");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function AAToolContactSearchEmailClick(seachKey, toolClickedName) {
  try {
    data.internal.search.term = seachKey;
    data.email.toolContactSearch = toolClickedName;
    window.digitalData = Object.assign(window.digitalData, data);
    window._satellite.track("Tools_Contact_Search_Email_Clicks");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function AATabClick(tabName) {
  try {
    data.tab.name = tabName;
    data.tile.name = "";
    window.digitalData = Object.assign(window.digitalData, data);
    window._satellite.track("Tab_Clicks");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

function AATileClick(tileName) {
  try {
    data.tile.name = tileName;
    window.digitalData = Object.assign(window.digitalData, data);
    window._satellite.track("Tile_Clicks");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

export {
  // AAPageLoad,
  AAPageLoadFromHome,
  AAPageLoadFromContact,
  AAPageLoadFromAddOrEditTool,
  AAPageLoadFromTopicsDeepDive,
  AAPageLoadFromSearchPage,
  AAcommon,
  AAGlobalAppSearch,
  AATabClick,
  AATileClick,
  AAToolSearchClick,
  AAToolContactSearchNameClick,
  AAToolContactSearchEmailClick,
};
