import { createMuiTheme } from "@material-ui/core";
const defaultTheme = createMuiTheme();
const {
  typography: { pxToRem },
} = defaultTheme;
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3EAD91",
    },
    secondary: {
      main: "#29BA74",
    },
    teritary: {
      main: "#197A56",
    },
    text: {
      primary: "#111111",
      secondary: "#808080",
      other: "#323232",
      white: "#FFFFFF",
    },
    error: {
      main: "#E71C57",
    },
    success: {
      main: "#03522D",
    },
    info: {
      main: "#30C1D7",
    },
    border: {
      default: "#535353",
      secondary: "#7F7F7F",
    },
    background: {
      default: "#FFFFFF",
      primary: "#C9E7CA",
      secondary: "#F2F2F2",
      error: "#EBC5D0",
      new: "#DEFBFF",
      draft: "#EEE89A",
      toolinput: "#C9C9C9",
      toolBorder: "#CCCCCC",
      processPackTile: "rgba(255, 255, 255, 0.85)",
      brown: "#7E6011",
      darkBrown: "#660F09",
    },
  },
  typography: {
    fontFamily: '"Henderson BCG Sans", "Helvetica", "Arial", "sans-serif"',
    h1: {
      fontFamily: '"Henderson BCG Mod", "Helvetica", "Arial", "sans-serif"',
      fontSize: pxToRem(28),
      fontWeight: "bold",
      lineHeight: 1.167,
      letterSpacing: "0px",
    },
    h5: {
      fontFamily: '"Henderson BCG Mod", "Helvetica", "Arial", "sans-serif"',
      fontSize: pxToRem(26),
      fontWeight: "bold",
      lineHeight: 1.334,
      letterSpacing: "0px",
    },
    h6: {
      fontFamily: '"Henderson BCG Mod", "Helvetica", "Arial", "sans-serif"',
      fontSize: pxToRem(20),
      fontWeight: "bold",
      lineHeight: 1.6,
      letterSpacing: "0px",
    },
    subtitle1: {
      fontFamily: '"Henderson BCG Sans", "Helvetica", "Arial", "sans-serif"',
      fontSize: pxToRem(16),
      fontWeight: "normal",
      letterSpacing: "0px",
    },
    subtitle2: {
      fontFamily: '"Henderson BCG Sans", "Helvetica", "Arial", "sans-serif"',
      fontSize: pxToRem(18),
      fontWeight: "normal",
      letterSpacing: "0px",
    },
    body1: {
      fontFamily: '"Henderson BCG Sans", "Helvetica", "Arial", "sans-serif"',
      fontSize: pxToRem(14),
      fontWeight: "normal",
      letterSpacing: "0px",
    },
    body2: {
      fontFamily: '"Henderson BCG Sans", "Helvetica", "Arial", "sans-serif"',
      fontSize: pxToRem(12),
      fontWeight: "normal",
      lineHeight: "19px",
      letterSpacing: "0px",
    },
    boldText: {
      fontWeight: "bold",
      fontSize: pxToRem(16),
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1025,
      lg: 1280,
      xl: 1920,
      xxl: 3840,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
    MuiIconButton: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {},
      label: {
        textTransform: "capitalize",
      },
    },
    MuiMenuItem: {
      root: {
        margin: defaultTheme.spacing(0, 0.5),
      },
    },
    MuiChip: {
      label: {
        textTransform: "capitalize",
        fontSize: "12px",
        paddingRight: "18px",
      },
    },
  },
});

export default theme;
