import BCGHendersonSansRegularTTF from "../assets/fonts/BCGHendersonSans/BCGHendersonSansRegular.ttf";
import BCGHendersonSansBoldTTF from "../assets/fonts/BCGHendersonSans/BCGHendersonSansBold.ttf";
import BCGHendersonModLightOTF from "../assets/fonts/BCGHendersonMod/BCGHendersonModLight.otf";
import BCGHendersonModBoldOTF from "../assets/fonts/BCGHendersonMod/BCGHendersonModBold.otf";

export const BCGHendersonSansRegular = {
  fontFamily: "Henderson BCG Sans",
  src: `
    url(${BCGHendersonSansRegularTTF})
  `,
};

export const BCGHendersonSansBold = {
  fontFamily: "Henderson BCG Sans Bold",
  src: `
      url(${BCGHendersonSansBoldTTF})
    `,
};

export const BCGHendersonMod = {
  fontFamily: "Henderson BCG Mod",
  src: `
        url(${BCGHendersonModLightOTF})
      `,
};

export const BCGHendersonModBold = {
  fontFamily: "Henderson BCG Mod Bold",
  src: `
        url(${BCGHendersonModBoldOTF})
      `,
};
