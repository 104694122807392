import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";

import { hideAlert } from "../../store/reducers/globalReducer";
import MaterialAlert from "@material-ui/lab/Alert";

const GlobalAlert = () => {
  const dispatch = useDispatch();
  const { isOpen, alertType, message } = useSelector(
    (state) => state.global.alert,
    shallowEqual
  );

  useEffect(() => {
    if (isOpen) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        dispatch(hideAlert({ isOpen: false }));
      }, 3000);
    }
  }, [isOpen, dispatch]);

  return (
    <>
      {isOpen && <MaterialAlert severity={alertType}>{message}</MaterialAlert>}
    </>
  );
};

export default GlobalAlert;
