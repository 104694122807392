import auth from "./authReducer";
import global from "./globalReducer";
import toolDetails from "./toolDetailsReducer";
import ui from "./uiReducer";

const rootReducer = {
  auth,
  global,
  toolDetails,
  ui,
};
export default rootReducer;
