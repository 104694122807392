import { createReducer } from "@reduxjs/toolkit";

import InitialState from "../initialState";
import {
  showSpinner,
  hideSpinner,
  setToolData,
  setUsecases,
  resetToolState,
  resetToolData,
  toggleToolUpdatedStatus,
  setUserCaseCodes,
} from "../actions/toolDetailsActions";
import { setToolUpdatedData } from "../../helpers/toolDetailsHelpers";

const initialState = InitialState.toolDetails;

const toolDetailsReducer = createReducer(initialState, {
  [showSpinner]: (state) => {
    state.showSpinner = true;
  },
  [hideSpinner]: (state) => {
    state.showSpinner = false;
  },
  [setToolData]: (state, { payload: data }) => {
    const updatedData = setToolUpdatedData(data);
    state.original = updatedData;
    state.current = updatedData;
  },
  [resetToolState]: (state) => {
    const { showSpinner, original, current, userCaseCodes } = initialState;
    state.showSpinner = showSpinner;
    state.original = original;
    state.current = current;
    state.userCaseCodes = userCaseCodes;
  },
  [resetToolData]: (state) => {
    state.current = state.original;
  },
  [setUsecases]: (state, { payload: data }) => {
    state.usecases = data;
  },
  [toggleToolUpdatedStatus]: (state, { payload }) => {
    state.toolUpdatedStatus = payload;
  },
  [setUserCaseCodes]: (state, { payload: data }) => {
    state.userCaseCodes = data;
  },
});

export default toolDetailsReducer;
