import axios from "axios";
import auth from "../config/auth";
import CONFIG from "../config/appConfig";

const axiosDefaults = {
  baseURL: CONFIG.SERVER_URL,
  headers: { "Content-Type": "application/json" },
  timeout: CONFIG.SERVER_TIMEOUT || 60000,
};
const AxiosInstance = axios.create({
  ...axiosDefaults,
});
AxiosInstance.interceptors.response.use(
  (response) => {
    switch (response.status) {
      default:
        return response.data;
    }
  },
  (error) => {
    //const status = error.response ? error.response.status : null;
    //return Promise.reject(error);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // window.location.reload();

          break;
        case 403:
          return Promise.reject({
            message: CONFIG.ERROR_MESSAGES.FORBIDDEN,
            error,
          });
        case 404:
          return Promise.reject({
            message: CONFIG.ERROR_MESSAGES.NOT_FOUND,
            error,
          });
        case 500:
          return Promise.reject({
            message: CONFIG.ERROR_MESSAGES.SERVER_ERROR,
            error,
          });
        default:
          return Promise.reject(
            new Error(CONFIG.ERROR_MESSAGES.UNKNOWN_CODE, error.response)
          );
      }
    } else if (error.request) {
      return Promise.reject(
        new Error(CONFIG.ERROR_MESSAGES.NETWORK_ERROR, error.request)
      );
    } else {
      return Promise.reject({ cancelled: true });
    }
  }
);
AxiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = auth.getAccessToken();
    const XApiKey_Updated = CONFIG.XAPI_KEY.replace(/"/g, "");
    return {
      ...config,
      headers: {
        "x-api-key": XApiKey_Updated,
        authorization: `Bearer ${accessToken}`,
        ...config.headers,
      },
    };
  },
  (err) => {
    return Promise.reject(err);
  }
);
function Service(
  url,
  method,
  data,
  params,
  optional = { "Content-Type": "application/json" },
  responseType
) {
  return AxiosInstance({
    url,
    // eslint-disable-next-line no-nested-ternary
    method: method ? method : data ? "POST" : "GET",
    data,
    headers: {
      ...optional,
    },
    params: params,
    responseType: responseType ? responseType : "",
  }).then((val) => {
    return val;
  });
}

export default Service;
