const getAllowedUserGroups = (componentKey) => {
  const { USER_GROUPS, UI_URL_KEY } = CONFIG;
  switch (componentKey) {
    case UI_URL_KEY.ADDTOOL:
    case UI_URL_KEY.REORDER:
      return [USER_GROUPS.CFS_ADMIN];
    default:
      return [USER_GROUPS.CFS_ADMIN];
  }
};

const CONFIG = {
  APP_URL: {
    HOME: "/",
    TOOLLISTING_URI: "/tools/:topicId",
    ADDNEWTOOL_URI: "/tool/addnew",
    EDITTOOL_URI: "/tool/:toolId",
    VIEWTOOL_URI: "/tool/view-tool/:toolId",
    NEWREQUESTS_URI: "/newrequests",
    CONTACTUS_URI: "/contact",
    SEARCH_URI: "/search",
    REORDER_URI: "/reorder",
    NOTFOUND_URI: "/404",
    UNAUTHORIZED: "/unauthorized",
  },
  API_URLS: {
    HOMETOP5: "/tools/top5Tools",
    HOMETOPICS: "/topics/allTopics",
    TOPICSFORCONTACT: "/contactus/contactsTopics",
    //TOOLSPERTOPIC: "/tools/toolsPerTopic/",
    TOOLSPERTOPIC: "/topics/",
    TOOLPERID: "/tools/toolPerToolId/",
    USECASES: "/usecases/allUseCases",
    CONTACTS: "/contactus/allContacts",
    DELETECONTACT: "contactus/deleteContact/",
    LISTSUBTOPICPERTOPIC: "topics/allSubTopics/",
    LISTSUBTOPICPERTOPICFORCONTACT: "contactus/allContactSubTopics/",
    EDITCONTACT: "contactus/updateContact/",
    ADDCONTACT: "contactus/addContact",
    LISTPEOPLE: "/domain/v2/people",
    FINANCE: "/finance/graphql",
    SEARCH: "/search",
    PEOPLEPICTURE: "/domain/v2/people/picture",
    GETHRID: "/token/getHrid",
    UPDATESUBTOPIC: "/topics/updateSubTopicName",
    UPDATEUSECASENAME: "/usecases/editUseCase/",
    UPDATETOP5: "/tools/top5Reorder/",
    DELUSECASE: "/usecases/checkDeleteUseCase/",
    DELUSECASEID: "/usecases/deleteUseCase/",
    DELSUBTOPIC: "/topics/checkDeleteSubTopic/",
    DELSUBTOPICID: "/topics/removeSubTopic",
    ADDUSECASE: "/usecases/addUseCase",
    STAFF_DETAIL_URL: (staffId) =>
      `http://link.bcg.com/people/default.aspx?accountname=` +
      staffId +
      `#/profile/overview`,
      STAFF_DETAIL_URL_NEW: (hrId) =>
      `https://profiles.navigator.bcg.com/overview?hrid=` +
      hrId +
      `&Source=CFS`,
    UPDATETOP4TOOLS: "tools/top4-update",
    USECASESLIST: "/usecases/onlyUseCases",
    USECASESREORDER: "/usecases/reorder",
    ADDEDITTOOL: "/tools/addTool",
    ADDSUBTOPIC: "/topics/addSubTopic",
    EDITTOPIC: "/topics/updateTopicName",
    VALIDATEANDADDCASECODE: "/casecode/addCasecode",
    GETCASECODEPERUSER: "/casecode/verifyCasecode",
    GETTABLEAUVIEW: "/casecode/fetchViewUrl/",
    PRE_SIGNED_URL: "/media/pre-singned-url",
    TOOL_URL: {
      FINANCIAL_TOOL: {
        USER_INFO: "fpl/is-admin",
        PROCESS_PACK: "/fpl/process-pack",
        PROCESS_PACK_DETAILS: "/fpl/process-pack-detail/",
        ADDITIONAL_PROCESS_GUIDE: "/fpl/additional-process_guide",
        VALIDATE_CASE_CODE: "/fpl/validateCaseCode",
        DOWNLOAD_PPT: "/fpl/download-process-pack-data",
        PROCESS_PACK_PRICE_CONFIRMATION: "/fpl/final-price",
        ADMIN: {
          MANAGE_LOGS: {
            LOGS_DATA: "fpl/log-data",
            LOGS_FILTER_DATA: "fpl/log-filter-data",
            DOWNLOAD_LOGS_DATA: "fpl/download-log-data",
            REVENUE_DATA: "fpl/price-detail",
          },
          MANAGE_ADMIN_ROLE: {
            GET_USERS: "fpl/admin-users",
            ADD_UPDATE_USER: "fpl/admin-user",
          },
          MANAGE_RATE_CARD: {
            RATE_CARD: "fpl/rate-card",
          },
          MANAGE_PROCESS_PACK: {
            GET_SLIDE_TYPE: "fpl/slide-type-detail",
            PROCESS_PACK: "fpl/process-pack-data",
          },
          MANEGE_FEEDBACK: {
            DOWNLOAD_FEEDBACK_DATA: "fpl/download-feedback-data",
          },
        },
        FEEDBACK: {
          GET_QUESTION_ANSWER: "fpl/feedback-question-answer",
          SAVE_FEEDBACK: "fpl/feedback",
        },
      },
      ORG_TOOL: {
        USER_INFO: "org/is-admin",
        FILTER_DATA: "org/orgChartSelectionData",
        COMPANY_COUNT: "org/orgChartCompanyCount",
        VALIDATE_CASE_CODE: "org/validateCaseCode",
        DOWNLOAD_PPT: "org/download-org-chart-data",
        ADMIN: {
          MANAGE_ADMIN_ROLE: {
            GET_USERS: "org/admin-users",
            ADD_UPDATE_USER: "org/admin-user",
          },
          MANAGE_LOGS: {
            LOGS_DATA: "org/log-data",
            LOGS_FILTER_DATA: "org/log-filter-data",
            DOWNLOAD_LOGS_DATA: "org/download-log-data",
            REVENUE_DATA: "org/price-detail",
          },
          MANAGE_RATE_CARD: {
            RATE_CARD: "org/rate-card",
          },
        },
      },
    },
  },
  SERVER_URL: process.env.REACT_APP_API_URL,
  SERVER_TIMEOUT: process.env.REACT_APP_API_TIMEOUT,
  XAPI_KEY: process.env.REACT_APP_XAPI_KEY,
  PEOPLEAPI_URL: process.env.REACT_APP_PEOPLEAPI_URL,
  OKTA: {
    ISSUER: process.env.REACT_APP_OKTA_URL,
    REDIRECT_URL: "/implicit/callback",
    CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
    SCOPES: ["openid", "profile", "email"],
    TOKEN_EARLY_EXPIRE: process.env.REACT_APP_OKTA_TOKEN_EARLY_REFRESH_SECS, // seconds
  },
  ALERT_TYPES: {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
  },
  ERROR_MESSAGES: {
    NOT_FOUND: "The server could not find the data",
    FORBIDDEN: "You do not have permissions to view this content",
    UNKNOWN_CODE: "The server returned an unknown HTTP error code.",
    SERVER_ERROR: "Server Error",
    NETWORK_ERROR: "There was a network error.",
    REQUEST_CANCELLED: "The request was cancelled.",
  },
  PEOPLEAPI_XAPI_KEY: process.env.REACT_APP_PEOPLEAPI_XAPI_KEY,
  USER_GROUPS: {
    CFS_ADMIN: "CFS Tools Admins",
    CFS_DL_ADMIN: "CFSTools Admin Access",
  },
  UI_URL_KEY: {
    ADDTOOL: "ADDTOOL",
    REORDER: "REORDER",
  },
  USER_ACCESS: (componentKey) => getAllowedUserGroups(componentKey),
};

export default CONFIG;
