import ApolloClient from "apollo-boost";
import isEmpty from "lodash/isEmpty";

import CONFIG from "./appConfig";
import auth from "./auth";

// export default new ApolloClient({
//   uri: CONFIG.PEOPLEAPI_URL + CONFIG.API_URLS.LISTPEOPLE,
//   headers: {
//     "x-api-key": CONFIG.PEOPLEAPI_XAPI_KEY,
//     Authorization: `Bearer ${token}`,
//     psid: "",
//   },
// });

export default function configureApolloClient() {
  const parsedOktaToken =
    localStorage &&
    localStorage.getItem("okta-token-storage") !== null &&
    JSON.parse(localStorage["okta-token-storage"])
      ? JSON.parse(localStorage["okta-token-storage"])
      : null;

  const token = !isEmpty(parsedOktaToken)
    ? parsedOktaToken.accessToken.accessToken
    : auth.getAccessToken();

  return new ApolloClient({
    uri: CONFIG.PEOPLEAPI_URL + CONFIG.API_URLS.LISTPEOPLE,
    headers: {
      "x-api-key": CONFIG.PEOPLEAPI_XAPI_KEY,
      Authorization: `Bearer ${token}`,
      psid: "",
    },
  });
}
