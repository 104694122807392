import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import classnames from "classnames";

import NavBar from "./NavBar";
import { ReactComponent as BCGLogo } from "../../assets/images/BCG_Logo.svg";
import CFS_Header from "../../assets/images/CFS_Header.png";

const useStyles = makeStyles((theme) => {
  return {
    header: {
      background:
        "transparent linear-gradient(90deg, #3EAD91 0%, #1F5749 100%) 0% 0% no-repeat padding-box",
    },
    toolbar: {
      padding: theme.spacing(0, 5),
      height: "68px",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 2),
      },
    },
    logo: {
      color: theme.palette.common.white,
      width: "116.85px",
      height: "40.61px",
      marginTop: "8px",
      [theme.breakpoints.down("sm")]: {
        width: "85px",
        height: "30px",
      },
    },
  };
});

const Header = ({ location: UrlLocation }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <AppBar position="static" className={classes.header} elevation={0}>
      <Toolbar disableGutters className={classes.toolbar}>
        <Box display="flex" alignItems="center" width="100%">
          <Box mr={{ xs: 2, lg: 4 }}>
            {/* <SvgIcon
              className={classes.logo}
              component={BCGLogo}
              viewBox="0 0 50.94 20.61"
            /> */}
            <Link
              component={RouterLink}
              to="/"
              color="textPrimary"
              className={classnames(classes.navLink, {
                [classes["navLink--active"]]:
                  UrlLocation &&
                  UrlLocation.pathname === "/" &&
                  !Boolean(anchorEl),
              })}
            >
              <img alt="CFS Header" src={CFS_Header} className={classes.logo} />
            </Link>
          </Box>
          <NavBar />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
