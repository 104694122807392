import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";

import { hideFrontAlert } from "../../store/reducers/globalReducer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const GlobalFrontAlert = () => {
  const dispatch = useDispatch();
  const { isOpen, alertType, message } = useSelector(
    (state) => state.global.frontAlert,
    shallowEqual
  );

  const handleClose = () => {
    dispatch(hideFrontAlert({ isOpen: false }));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{ textTransform: "capitalize" }}
        id="alert-dialog-title"
      >
        {alertType}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalFrontAlert;
