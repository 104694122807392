import InitialState from "../initialState";
import { createSlice } from "@reduxjs/toolkit";

const initialState = InitialState.auth;

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUserInfo: (state, { payload }) => {
      state.user = payload;
    },
    setRole: (state, { payload }) => {
      state.role = payload;
    },
    setUserIdByTool: (state, { payload }) => {
      state.toolUser = {...state.toolUser, [payload.tool] : payload.user}
    }
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo, setRole, setUserIdByTool } = authSlice.actions;

export default authSlice.reducer;
