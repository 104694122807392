import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";

import App from "../App";
import NotFound from "../pages/NotFound";
import CONFIG from "../config/appConfig";
import { store } from "../store";
import auth from "../config/auth";

const AppRouter = () => {
  const loadAdobeScript = () => {
    const analyticsUrl = process.env.REACT_APP_ADOBE_SCRIPT;
    if (analyticsUrl) {
      const script = document.createElement("script");
      script.src = analyticsUrl;
      script.async = true;
      document.head.appendChild(script);
    }
  };
  useEffect(() => {
    loadAdobeScript();
  }, []);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Security oktaAuth={auth}>
          <Switch>
            <Route path={CONFIG.OKTA.REDIRECT_URL} component={LoginCallback} />
            <Route
              path={CONFIG.APP_URL.NOTFOUND_URI}
              exact
              component={NotFound}
            />
            <SecureRoute path="/" component={App} />
          </Switch>
        </Security>
      </BrowserRouter>
    </Provider>
  );
};

export default AppRouter;
