import { OktaAuth } from "@okta/okta-auth-js";
import CONFIG from "./appConfig";

export default new OktaAuth({
  issuer: CONFIG.OKTA.ISSUER,
  clientId: CONFIG.OKTA.CLIENT_ID,
  redirectUri: `${window.location.origin}${CONFIG.OKTA.REDIRECT_URL}`,
  scopes: CONFIG.OKTA.SCOPES,
  pkce: false,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: CONFIG.OKTA.TOKEN_EARLY_EXPIRE * 60, // Emit expired event 5 minutes before expiration . Tokens accessed with tokenManager.get() will auto-renew within 5 minutes of expiration
  },
});
