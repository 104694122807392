import React from "react";
import { TitleComponent } from "./PageTitle";

const withTitle = ({ component: Component, title }) => {
  return (props) => {
    return (
      <>
        <TitleComponent title={title} />
        <Component {...props} />
      </>
    );
  };
};
export default withTitle;
