import { createAction } from "@reduxjs/toolkit";
import Service from "../../service";
import CONFIG from "../../config/appConfig";
import { isEmptyOrNull } from "../../utils";
import { noImageData } from "../../constants";

const ui = "ui";
export const setTopicData = createAction(`${ui}/setTopicData`);
export const setPeopleImage = createAction(`${ui}/setPeopleImage`);
export const setUserHrId = createAction(`${ui}/setUserHrId`);
export const setReorderTopicId = createAction(`${ui}/setReorderTopicId`);
export const setUsersByHrId = createAction(`${ui}/setUsersByHrId`);
export const setUsersByEmail = createAction(`${ui}/setUsersByEmail`);

//Async call
export const fetchTopic = () => {
  return async (dispatch) => {
    try {
      const response = await Service(CONFIG.API_URLS.HOMETOPICS, "GET");
      !isEmptyOrNull(response) && dispatch(setTopicData(response));
    } catch (err) {
      console.log(err.message);
    }
  };
};

export const fetchPeopleImage = () => {
  return async (dispatch) => {
    try {
      const extraHeaders = {
        headers: {
          "x-api-key": CONFIG.PEOPLEAPI_XAPI_KEY,
          psid: "",
        },
        responseType: "arraybuffer",
      };
      try {
        const hrIdResponse = await Service(CONFIG.API_URLS.GETHRID, "GET");
        localStorage.setItem("hrId", hrIdResponse);
        if (!isEmptyOrNull(hrIdResponse)) {
          const response = await Service(
            CONFIG.PEOPLEAPI_URL + CONFIG.API_URLS.PEOPLEPICTURE,
            "GET",
            null,
            {
              hrid: hrIdResponse,
            },
            extraHeaders.headers,
            extraHeaders.responseType
          );
          if (response) {
            const byteSequence = new Uint8Array(response).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            );
            const peopleImageData = `data:image/jpeg;base64,${btoa(
              byteSequence
            )}`;
            if (peopleImageData !== noImageData)
              dispatch(setPeopleImage(peopleImageData));
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    } catch (err) {
      console.error(err.message);
    }
  };
};

export const fetchAllPeople = ({ query = {} }) => {
  return async (dispatch) => {
    try {
        let loading = true;
        let result = {
          loading,
          error: null,
          data: null,
        };
        dispatch(setUsersByHrId(result));
  
        const extraHeaders = {
          headers: {
            "x-api-key": CONFIG.PEOPLEAPI_XAPI_KEY,
            psid: "",
          },
          responseType: "",
        };
  
        const response = await Service(
          CONFIG.PEOPLEAPI_URL + CONFIG.API_URLS.LISTPEOPLE,
          "POST",
          { query },
          '',
          extraHeaders.headers,
          extraHeaders.responseType
        );
  
        loading = false;
        result = {
          loading,
          error: null,
          data: response?.data || null,
        };
        if(response) {
          dispatch(setUsersByHrId(result));
        }
    } catch (err) {
        const loading = false;
        let result = {
          loading,
          error: err,
          data: null,
        };
        dispatch(setUsersByHrId(result));
    }
  }
};

export const fetchAllPeopleClearData = () => {
  return async (dispatch) => {
    let result = {
      loading: false,
      error: null,
      data: null,
    };
    dispatch(setUsersByHrId(result));
  }
}

export const fetchEmployeeByEmail = ({ query = {} }) => {
  return async (dispatch) => {
    try {
        let loading = true;
        let result = {
          loading,
          error: null,
          data: null,
        };
        dispatch(setUsersByEmail(result));
  
        const extraHeaders = {
          headers: {
            "x-api-key": CONFIG.PEOPLEAPI_XAPI_KEY,
            psid: "",
          },
          responseType: "",
        };
  
        const response = await Service(
          CONFIG.PEOPLEAPI_URL + CONFIG.API_URLS.LISTPEOPLE,
          "POST",
          { query },
          '',
          extraHeaders.headers,
          extraHeaders.responseType
        );
  
        loading = false;
        result = {
          loading,
          error: null,
          data: response?.data?.searchFilter?.employees[0] || null,
        };
        if(response) {
          dispatch(setUsersByEmail(result));
        }
    } catch (err) {
        const loading = false;
        let result = {
          loading,
          error: err,
          data: null,
        };
        dispatch(setUsersByEmail(result));
    }
  }
};
