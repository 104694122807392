import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

const TitleComponent = ({ title }) => {
  let defaultTitle = "CFS Tools";
  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
    </Helmet>
  );
};

TitleComponent.propTypes = {
  title: PropTypes.string,
};

export { TitleComponent };
