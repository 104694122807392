import includes from "lodash/includes";
import moment from "moment";

export const setToolUpdatedData = (data) => {
  const {
    toolId,
    title = "",
    featuredImgUrl = "",
    toolInfo = "",
    topicId = null,
    subTopicId = null,
    productSummary = "",
    toolUrl = "",
    sip = false,
    visible = true,
    caseCodeValidity = "",
    statusId = null,
    topicName = "",
    subTopicName = "",
    useCases = [],
    toolsAdditionalInformationID,
    kpi = "",
    keyFeatures = "",
    keyQuestions = "",
    moreInfo1 = "",
    moreInfo2 = "",
    moreInfo3 = "",
    moreInfo1Title = "",
    moreInfo2Title = "",
    moreInfo3Title = "",

    moreInfoKeysArr = [{ title: "", content: "" }],
    toolScreens = [],
    infoDownload = [],
    toolVideos = "",
    viewTool = "",
    hrid = [],
    contactInfo = "",
    top5 = false,
    isTop4,
    top4Order,
  } = { ...data };

  let result = {
    toolId,
    title,
    featuredImgUrl: featuredImgUrl || "",
    toolInfo,
    topicId,
    subTopicId,
    productSummary: productSummary || "",
    toolUrl,
    sip,
    visible,
    caseCodeValidity,
    statusId,
    topicName,
    subTopicName,
    useCases: useCases || [],
    toolsAdditionalInformationID,
    kpi,
    keyFeatures,
    keyQuestions,
    moreInfo1,
    moreInfo2,
    moreInfo3,
    moreInfo1Title,
    moreInfo2Title,
    moreInfo3Title,

    moreInfoKeysArr: moreInfoKeysArr || [{}],
    toolScreens: toolScreens || [],
    infoDownload: infoDownload || [],
    toolVideos: toolVideos || "",
    viewTool: viewTool || "",
    hrid: hrid || [],
    contactInfo,
    top5,
    isTop4,
    top4Order,
  };

  return result;
};

export const getInitialValuesForForm = ({
  toolId,
  title,
  featuredImgUrl,
  productSummary,
  topicName,
  subTopicName,
  topicId,
  subTopicId,
  useCases,
  toolInfo,
  kpi,
  keyFeatures,
  keyQuestions,
  moreInfo1,
  moreInfo2,
  moreInfo3,
  moreInfo1Title,
  moreInfo2Title,
  moreInfo3Title,

  moreInfoKeysArr,
  infoDownload,
  viewTool,
  sip,
  visible,
  caseCodeValidity,
  toolVideos,
  toolScreens,
  hrid,
  contactInfo,
  isTop4,
  top4Order,
}) => {
  return {
    toolId,
    title,
    featuredImgUrl,
    productSummary,
    topicName,
    subTopicName,
    useCases,
    toolInfo,
    kpi,
    keyFeatures,
    keyQuestions,
    moreInfo1,
    moreInfo2,
    moreInfo3,
    moreInfo1Title,
    moreInfo2Title,
    moreInfo3Title,

    moreInfoKeysArr,
    infoDownload,
    viewTool,
    sip,
    visible,
    caseCodeValidity,
    toolVideos,
    toolScreens,
    toolImages: [],
    hrid,
    contactInfo,
    topicId,
    subTopicId,
    infoDownloadFile: null,
    FeaturedImgUrlFile: null,
    isTop4,
    top4Order,
  };
};

export const validateUrl = (url) => {
  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return url ? urlRegex.test(url.toLowerCase()) : false;
};

export const validateVideoUrl = (url) => {
  return includes(url, "players.brightcove.net");
};

export const validateEmail = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return emailRegex.test(email);
};

export const getDiffDays = (startDate, endDate) => {
  let start = moment(startDate);
  let end = moment(endDate);
  return end.diff(start, "days");
};

export const getFormData = (formData, data, previousKey) => {
  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (
        value instanceof Object &&
        !Array.isArray(value) &&
        !value instanceof File
      ) {
        return this.getFormData(formData, value, key);
      }
      if (previousKey) {
        key = `${previousKey}[${key}]`;
      }
      if (Array.isArray(value)) {
        if (value.length === 0 && key !== "toolImages") {
          formData.append(`${key}`, value);
        }
        value.forEach((val) => {
          if (val instanceof File)
            formData.append(`${key}`, val, `${Date.now()}_${val.name}`);
          else if (typeof val !== "undefined" && val !== null)
            formData.append(`${key}`, val.toString());
        });
      } else if (value instanceof File) {
        formData.append(`${key}`, value, `${Date.now()}_${value.name}`);
      } else if (typeof value !== "undefined" && value !== null) {
        formData.append(key, value.toString());
      }
    });
    return formData;
  }
};

export const getFormDataStringify = (formData, data, previousKey) => {
  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      //outdated
      // if (value instanceof File) {
      //   if (Array.isArray(value)) {
      //     value.forEach((val) => {
      //       formData.append(`${key}[]`, val);
      //     });
      //   } else {
      //     formData.append(key, value);
      //   }
      // } else {
      //   formData.append(key, JSON.stringify(value));
      // }
      if (Array.isArray(value) && key === "toolImages") {
        value.forEach((val) => {
          if (value instanceof File) {
            formData.append(`${key}`, val);
          }
        });
      } else if (value instanceof File) {
        formData.append(key, value);
      } else if (typeof value !== "undefined" && value !== null) {
        formData.append(key, JSON.stringify(value));
      }
    });
    return formData;
  }
};

export const convertModelToFormData = (val, formData, namespace = "") => {
  if (typeof val !== "undefined" && val !== null) {
    if (val instanceof Date) {
      formData.append(namespace, val.toISOString());
    } else if (val instanceof Array) {
      for (let i = 0; i < val.length; i++) {
        convertModelToFormData(val[i], formData, namespace + "[" + i + "]");
      }
    } else if (typeof val === "object" && !(val instanceof File)) {
      for (let propertyName in val) {
        if (val.hasOwnProperty(propertyName)) {
          convertModelToFormData(
            val[propertyName],
            formData,
            namespace ? `${namespace}[${propertyName}]` : propertyName
          );
        }
      }
    } else if (val instanceof File) {
      formData.append(namespace, val);
    } else {
      formData.append(namespace, val.toString());
    }
  }
  return formData;
};
