import React, { useEffect, Suspense, lazy } from "react";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch, Redirect } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ApolloProvider } from "@apollo/react-hooks";
import { isNil, includes } from "lodash";
import { AAcommon } from "./analytics/AnalyticsCapture";

import Header from "./components/Header";
import Footer from "./components/Footer";
import withTitle from "./components/PageTitle";
import theme from "./theme";
import { BCGHendersonSansRegular, BCGHendersonMod } from "./theme/fonts";
import CONFIG from "./config/appConfig";
import apolloClient from "./config/graphQLClient";
import { setUserInfo, setRole } from "./store/reducers/authReducer";
import OverlaySpinner from "./components/OverlaySpinner";
import Alert from "./components/Alert";
import GlobalSpinner from "./components/OverlaySpinner/GlobalSpinner";
import RoleRouting from "./routes/RoleBasedRouting";
import UnAuthorized from "./pages/UnAuthorized";
import GlobalFrontAlert from "./components/Alert/FrontAlert";
//const theme = responsiveFontSizes(Theme); //add import if uncommented responsiveFontSizes

const Home = lazy(() => import("./pages/Home"));
const ToolsListing = lazy(() => import("./pages/ToolsListing"));
const AddEdit = lazy(() => import("./pages/AddEdit"));
const ViewTool = lazy(() => import("./pages/ViewTool"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const SearchResults = lazy(() => import("./pages/SearchResults"));
const Reorder = lazy(() => import("./pages/Reorder"));

const styles = () => {
  return {
    main: { marginBottom: "50px" },
    "@global": {
      "@font-face": [BCGHendersonSansRegular, BCGHendersonMod],
    },
  };
};

const HomePage = withTitle({
  component: Home,
  title: "CFS Tools - Home",
});

const AddEditPage = withTitle({
  component: AddEdit,
  title: "CFS Tools - Tool Details",
});

const ViewToolPage = withTitle({
  component: ViewTool,
  title: "CFS Tools - View Tool",
});

const ToolsListingPage = withTitle({
  component: ToolsListing,
  title: "CFS Tools - Tools Listing",
});

const ContactUsPage = withTitle({
  component: ContactUs,
  title: "CFS Tools - Contact Us",
});

const SearchResultsPage = withTitle({
  component: SearchResults,
  title: "CFS Tools - Search Results",
});

const ReorderPage = withTitle({
  component: Reorder,
  title: "CFS Tools - Reorder",
});
const UnAuthorizedPage = withTitle({
  component: UnAuthorized,
  title: "CFS Tools - UnAuthorized Page",
});

function App({ classes }) {
  const { authState, oktaAuth } = useOktaAuth();

  const Groups = useSelector(
    (state) => state.auth.user && state.auth.user.Groups,
    shallowEqual
  );
  // const AdminGroups = [
  //   "Enrich_CMP_API_ACCESS",
  //   "CFS Tools Admins",
  //   "CMP_CaseCapture_Response_Users",
  // ];
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(theme);
  }, []);

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((userInfo) => {
        localStorage.setItem("email", userInfo.email);
        if (!localStorage.getItem("pageName")) {
          AAcommon(localStorage.getItem("email"));
        }
        dispatch(setUserInfo(userInfo));
      });
    }
  }, [authState, oktaAuth, dispatch]);

  useEffect(() => {
    if (!isNil(Groups)) {
      let cfsAdmin, cfsUser, role;
      cfsAdmin =
        includes(Groups, CONFIG.USER_GROUPS.CFS_ADMIN) ||
        includes(Groups, CONFIG.USER_GROUPS.CFS_DL_ADMIN);
      cfsUser = !cfsAdmin;
      role = {
        cfsAdmin: cfsAdmin,
        cfsUser: cfsUser,
      };
      dispatch(setRole(role));
    }
  }, [Groups, dispatch]);

  const client = apolloClient();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Alert />
        <GlobalFrontAlert />
        <GlobalSpinner />
        <main className={classes.main}>
          <Suspense fallback={<OverlaySpinner />}>
            <Switch>
              <Route path={CONFIG.APP_URL.HOME} exact component={HomePage} />
              {/* <Route
                path={CONFIG.APP_URL.ADDNEWTOOL_URI}
                exact
                component={AddEditPage}
              /> */}
              <RoleRouting
                path={CONFIG.APP_URL.ADDNEWTOOL_URI}
                exact
                allowedUsers={CONFIG.USER_ACCESS(CONFIG.UI_URL_KEY.ADDTOOL)}
                authorities={Groups}
                component={AddEditPage}
              />
              <Route
                path={CONFIG.APP_URL.EDITTOOL_URI}
                exact
                component={AddEditPage}
              />
              <Route
                path={CONFIG.APP_URL.VIEWTOOL_URI}
                exact
                component={ViewToolPage}
              />
              <Route
                path={CONFIG.APP_URL.TOOLLISTING_URI}
                exact
                component={ToolsListingPage}
              />
              <Route
                path={CONFIG.APP_URL.CONTACTUS_URI}
                exact
                component={ContactUsPage}
              />
              <Route
                path={CONFIG.APP_URL.SEARCH_URI}
                exact
                component={SearchResultsPage}
              />
              <RoleRouting
                path={CONFIG.APP_URL.REORDER_URI}
                exact
                allowedUsers={CONFIG.USER_ACCESS(CONFIG.UI_URL_KEY.REORDER)}
                // authorities={AdminGroups}
                authorities={Groups}
                component={ReorderPage}
              />

              <Route
                path={CONFIG.APP_URL.UNAUTHORIZED}
                exact
                component={UnAuthorizedPage}
              />

              <Route
                render={() => <Redirect to={CONFIG.APP_URL.NOTFOUND_URI} />}
              />
            </Switch>
          </Suspense>
        </main>
        <Footer />
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default withStyles(styles)(App);
