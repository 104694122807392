import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { isEmpty } from "lodash";

import { grantPermission } from "../utils";
import CONFIG from "../config/appConfig";
import OverlaySpinner from "../components/OverlaySpinner";

const RoleBasedRouting = ({
  component: Component,
  allowedUsers,
  authorities,
  ...rest
}) => {
  if (isEmpty(authorities)) return <OverlaySpinner />;
  return (
    <Fragment>
      <Route
        {...rest}
        render={(props) =>
          grantPermission(allowedUsers, authorities) ? (
            <Fragment>
              <Component {...props} />
            </Fragment>
          ) : (
            <Redirect to={CONFIG.APP_URL.UNAUTHORIZED} />
          )
        }
      />
    </Fragment>
  );
};

RoleBasedRouting.propTypes = {
  allowedUsers: PropTypes.array,
  component: PropTypes.elementType,
  requestedUsers: PropTypes.array,
};

export default RoleBasedRouting;
