import { isArray, isEmpty, intersection } from "lodash";
import queryString from "query-string";

let lastId = 0;

export const uniqueId = (prefix = "id") => {
  lastId++;
  return `${prefix}${lastId}`;
};

export const isEmptyOrNull = (value) => {
  if (value === "" || value === null || value === undefined) return true;
  else if (isArray(value)) return value.length === 0;
  else if (typeof value === "number") return value < 0;
  else if (typeof value === "string") return value.length === 0;
  else if (isEmpty(value)) return true;
  else return false;
};

export const paginate = (data, itemsPerPage) => {
  const numberOfPages = Math.ceil(data.length / itemsPerPage);
  const paginatedData = Array.from({ length: numberOfPages }, (_, index) => {
    const start = index * itemsPerPage;
    return data.slice(start, start + itemsPerPage);
  });
  return paginatedData;
};

export const grantPermission = (permittedRoles, requestedRoles) => {
  const isUserAllowedForComponentAccess = !isEmpty(
    intersection(permittedRoles, requestedRoles)
  );
  if (isUserAllowedForComponentAccess) return true;
  return false;
};

export const checkAbsoluteLink = (link) => {
  //Fn to check if link is absolute
  var absoluteLink = new RegExp("^(?:[a-z]+:)?//", "i");
  return absoluteLink.test(link);
};

export const breadcrumbName = (topicName, toolTitle, topicId) => {
  return {
    TOPICNAME_URL: `${topicName}`,
    TOOLTITLE_URL: `${toolTitle}`,
    TOPICID: `${topicId}`,
  };
};

export const getQueryParams = (locationSearch) =>
  queryString.parse(locationSearch);
