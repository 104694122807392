import CONFIG from "../config/appConfig";
import toolDetailsInitialState from "./toolDetailsInitialState";

const InitialState = {
  auth: {
    user: null,
    role: {
      cfsUser: false,
    },
    toolUser: {},
  },
  reordertopidId: {
    topicId: null,
  },
  globalData: {
    showSpinner: false,
    alert: {
      isOpen: false,
      alertType: CONFIG.ALERT_TYPES.SUCCESS,
      message: "",
    },
    frontAlert: {
      isOpen: false,
      alertType: CONFIG.ALERT_TYPES.SUCCESS,
      message: "",
    },
  },
  toolDetails: {
    original: toolDetailsInitialState,
    current: toolDetailsInitialState,
    showSpinner: false,
    toolUpdatedStatus: false,
    usecases: [],
    userCaseCodes: [],
  },
  ui: {
    topics: [],
    peopleImage: "",
    userHrId: "",
    reorderTopicId: null,
    users: {
      loading: false,
      error: null,
      data: null,
    },
    userbyEmail: {
      loading: false,
      error: null,
      data: null,
    }
  },
};

export default InitialState;
