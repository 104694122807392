import { createAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";

import Service from "../../service";
import CONFIG from "../../config/appConfig";
import {
  showAlert,
  showSpinner as globalShowSpinner,
  hideSpinner as globalHideSpinner,
} from "../reducers/globalReducer";

const toolDetails = "toolDetails";
export const setToolData = createAction(`${toolDetails}/setToolData`);
export const setUsecases = createAction(`${toolDetails}/setUsecases`);
export const setUserCaseCodes = createAction(`${toolDetails}/setUserCaseCodes`);

export const resetToolState = createAction(`${toolDetails}/resetToolState`);
export const resetToolData = createAction(`${toolDetails}/resetToolData`);

//Loading Actions
export const showSpinner = createAction(`${toolDetails}/showSpinner`);
export const hideSpinner = createAction(`${toolDetails}/hideSpinner`);

export const toggleToolUpdatedStatus = createAction(
  `${toolDetails}/toggleToolUpdatedStatus`
);

//Async call
export const fetchToolDetails = (id) => {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      const response = await Service(CONFIG.API_URLS.TOOLPERID + id, "get");
      dispatch(setToolData(response));
      dispatch(hideSpinner());
      dispatch(toggleToolUpdatedStatus(false));
    } catch (err) {
      dispatch(hideSpinner());
      dispatch(toggleToolUpdatedStatus(false));
      dispatch(
        showAlert({
          isOpen: true,
          alertType: CONFIG.ALERT_TYPES.ERROR,
          message: err.message,
        })
      );
    }
  };
};

export const saveToolDetails = (postData, toolID) => {
  return async (dispatch) => {
    dispatch(globalShowSpinner());
    try {
      dispatch(toggleToolUpdatedStatus(false));
      const response = await Service(
        CONFIG.API_URLS.ADDEDITTOOL,
        "POST",
        postData,
        {},
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (response) {
        batch(() => {
          dispatch(
            showAlert({
              isOpen: true,
              alertType: CONFIG.ALERT_TYPES.SUCCESS,
              message: "Tool Saved Successfully",
            })
          );
          dispatch(toggleToolUpdatedStatus(true));
        });
      } else {
        batch(() => {
          dispatch(
            showAlert({
              isOpen: true,
              alertType: CONFIG.ALERT_TYPES.ERROR,
              message: "Something went wrong",
            })
          );
          dispatch(toggleToolUpdatedStatus(true));
        });
      }
      dispatch(globalHideSpinner());
    } catch (err) {
      dispatch(globalHideSpinner());
      batch(() => {
        dispatch(
          showAlert({
            isOpen: true,
            alertType: CONFIG.ALERT_TYPES.ERROR,
            message: err.message,
          })
        );
        dispatch(toggleToolUpdatedStatus(true));
      });
    }
  };
};

export const fetchUseCases = () => {
  return async (dispatch) => {
    try {
      const response = await Service(CONFIG.API_URLS.USECASESLIST, "get");
      if (response.useCasesList) {
        let useCaseReorder =
          response.useCasesList &&
          response.useCasesList.length > 0 &&
          response.useCasesList.sort((a, b) => a.useCaseOrder - b.useCaseOrder);
        dispatch(setUsecases(useCaseReorder));
      } else throw new Error("UseCases not loaded..");
    } catch (err) {
      console.error(err);
    }
  };
};
