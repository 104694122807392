import React from "react";
import { Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

import CONFIG from "../config/appConfig";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      backgroundColor: theme.palette.background.secondary,
      padding: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
      height: "calc(100vh - 120px)",
    },
    info: {
      cursor: "pointer",
    },
  };
});

const UnAuthorized = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Alert severity="warning">
          <Typography variant="subtitle2">
            You don't have proper permissions to view this page. <br />
            Navigate to &nbsp;
            <Link
              component={RouterLink}
              className={classes.info}
              to={CONFIG.APP_URL.HOME}
            >
              Home
            </Link>
          </Typography>
        </Alert>
      </div>
    </div>
  );
};

export default UnAuthorized;
